<template>
    <div :id="dom" style="height: 600px; width: 100%"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
    props: {
        dom: null,
        xAxis: null,
        legendData: null,
        series: null,
        loading: null,
        zoomStart: null
    },
    data() {
        return {
            chart: null,
            options: {
                grid: {
                    left: 50,
                    right: '2%',
                },
                title: {
                    text: "Header Manager Generated Tasks",
                },
                tooltip: {
                    trigger: "axis",
                },
                xAxis: {
                    axisLine: {
                        onZero: false,
                    },
                    type: "category",
                    data: this.xAxis,
                },
                dataZoom: [
                    {
                        type: 'inside',
                        start: this.zoomStart,
                        end: 100
                    },{
                        show: true,
                        type: 'slider',
                        top: '90%',
                        start: 50,
                        end: 100
                    }
                ],
                yAxis: {
                    type: "value",
                    axisLabel: {
                        show: true,
                        formatter: (val) => {
                            let abVal = Math.abs(val);
                            if (abVal >= 1000 && abVal < 1000000) {
                                val = (val / 1000).toFixed(2) + "K";
                            } else if (abVal >= 1000000 && abVal < 1000000000) {
                                val = (val / 1000000).toFixed(2) + "M";
                            } else if (abVal >= 1000000000) {
                                val = (val / 1000000000).toFixed(2) + "B";
                            } else {
                                val = val;
                            }
                            return val;
                        },
                    },
                },
                legend: {
                    data: this.legendData,
                    width: 1000,
                },
                series: this.series,
            },
        };
    },
    methods: {
        init() {
            this.chart = echarts.init(document.getElementById(this.dom));
            if (this.loading) {
                this.chart.showLoading({
                    text : "Loading Data..."
                });
            } else {
                
                if (this.series.length == 0) {
                    this.options.title = {
                        backgroundColor: "rgba(128, 128, 128, 0.5)",
                        effect: "bubble",
                        x: 'center',
                        y: 'center',
                        text: "No Data",
                        effectOption:{
                            effect:{
                                n: 0
                            }
                        },
                        textStyle:{
                            fontSize: 28,
                            fontWeight: "bold"
                        }
                    }
                }

                this.chart.setOption(this.options);
                this.chart.hideLoading();
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        legendData(nv, ov) {
            this.options.legend.data = nv;
            this.chart.dispose();
            this.init();
        },
        xAxis(nv, ov) {
            this.options.xAxis.data = nv;
            this.chart.dispose();
            this.init();
        },
        series(nv, ov) {
            let max = Math.max.apply(Math, nv[0].data);
            let min = Math.min.apply(Math, nv[0].data);

            console.log(max, " | ", min);
            
            this.options.series = nv;
            this.options.yAxis["max"] = max;
            this.options.yAxis["min"] = min;
            this.chart.dispose();
            this.init();
        },
    },
};
</script>
<template>
    <div>
        <v-card>
            <v-container fluid>
                <v-row class="pa-0">
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <h3>Header Manager Nodes Status</h3>
                        <br />
                        <v-data-table
                            dense
                            :headers="tableHeaderManagerNodesStatus"
                            :items="dataManagerNodesStatus"
                            :header-props="{ sortIcon: null }"
                            class="elevation-0 alert-datatable"
                            :items-per-page="-1"
                            :height="150"
                            fixed-header
                            :hide-default-footer="true"
                        >
                        </v-data-table>
                    </v-col>
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <h3>Header Manager Dropped Tasks</h3>
                        <br />
                        <LineChart
                            :dom="'la'"
                            :xAxis="dataManagerDroppedTasksChartData[0]"
                            :series="dataManagerDroppedTasksChartData[1]"
                            :loading="false"
                            :legendData="['Num']"
                            :zoomStart="0"
                        />
                        <!-- <v-data-table dense :headers="tableHeaderManagerDroppedTasks" :items="dataManagerDroppedTasks" 
                            class="elevation-0 alert-datatable"
                            :items-per-page="-1" :height="300" fixed-header :hide-default-footer="true">
                        </v-data-table> -->
                    </v-col>
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <h3>Header Manager Generated Tasks</h3>
                        <br />
                        <LineChart
                            :dom="'lc'"
                            :xAxis="dataManagerGeneratedTasksChartData[0]"
                            :series="dataManagerGeneratedTasksChartData[1]"
                            :loading="false"
                            :legendData="['Num']"
                            :zoomStart="95"
                        />
                        <!-- <v-data-table dense :headers="tableHeaderManagerGeneratedTasks" :items="dataManagerGeneratedTasks" 
                            class="elevation-0 alert-datatable"
                            :items-per-page="-1" :height="300" fixed-header :hide-default-footer="true">
                        </v-data-table> -->
                    </v-col>
                </v-row>
                <v-row class="pa-0">
                    <v-col lg="4" md="4" xs="12" cols="12">
                        {{ dataPublisherBookDroppedTasks }}
                    </v-col>
                    <v-col lg="4" md="4" xs="12" cols="12">
                        {{ dataPublisherUserDroppedTasks }}
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { helper } from "@components/mixins/helper";
import { position } from "@components/mixins/position";
import { permission } from "@components/mixins/permission";
import LineChart from "./components/ComponentLineChart";

export default {
    components: { LineChart },
    mixins: [helper, position, permission],
    data() {
        return {
            monitorSocket: null,
            load: { largeVolume: 0 },
        };
    },
    computed: {
        ...mapState("K8S", [
            "tableHeaderManagerNodesStatus",
            "tableHeaderManagerDroppedTasks",
            "tableHeaderManagerGeneratedTasks",
            "dataManagerNodesStatus",
            "dataManagerDroppedTasks",
            "dataManagerGeneratedTasks",
            "dataPublisherBookDroppedTasks",
            "dataPublisherUserDroppedTasks",
        ]),
        dataManagerGeneratedTasksChartData() {
            let x = [];
            let y = { name: "Num", type: "line", data: [] };
            this.dataManagerGeneratedTasks.forEach((item) => {
                x.push(parseInt(item.minutes));
                y.data.push(parseInt(item.num));
            });

            y = [y];

            return [x, y];
        },
        dataManagerDroppedTasksChartData() {
            let x = [];
            let y = { name: "Num", type: "line", data: [] };
            this.dataManagerDroppedTasks.forEach((item) => {
                x.push(parseInt(item.minutes));
                y.data.push(parseInt(item.num));
            });

            y = [y];

            return [x, y];
        },
    },
    methods: {
        ...mapActions("K8S", [
            "getManagerNodeStatusAction",
            "getManagerDroppedTasksAction",
            "getManagerGeneratedTasksAction",
            "getPublisherBookDroppedTasksAction",
            "getPublisherUserDroppedTasksAction",
        ]),
    },
    mounted() {
        this.getManagerNodeStatusAction();
        this.getManagerDroppedTasksAction();
        this.getManagerGeneratedTasksAction();
        this.getPublisherBookDroppedTasksAction();
        this.getPublisherUserDroppedTasksAction();
    },
    destroyed() {},
};
</script>
